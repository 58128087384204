@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

.keep-together {
  page-break-inside: avoid;
}
