.checkboxGroup {
  color: #888;
  padding-top: 2px;
  padding-bottom: 2px;
}
.checkboxGroup:hover {
  color: #000;
}
.checkboxGroup.noSelect:hover {
  color: #888;
}
.checkboxGroup label,
.checkboxGroup input {
  cursor: pointer;
}
.checkboxGroup.noSelect label,
.checkboxGroup.noSelect input {
  cursor: auto;
}

.checkboxGroup label {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}

.capitalize {
  text-transform: capitalize;
}
.disabled {
  color: #ccc !important;
}
