.sk-search-box__text {
  width: 100%;
}

.sk-search-box__action {
  display: none;
}

em {
  background: yellow;
}
