.ReactModal__Content .row {
  margin-left: 0;
  margin-right: 0;
}
.ReactModal__Content .modal-body {
  overflow: auto;
  max-height: calc(100vh - 294px);
}

.ReactModal__Content {
  top: 100px;
  transform: translate(-50%, 0);
}

@media screen and (max-height: 768px) {
  .ReactModal__Content {
    top: 15px;
    transform: translate(-50%, 0);
  }
  .ReactModal__Content .modal-body {
    max-height: calc(100vh - 180px);
  }
}
/*
@media screen and (max-height: 568px) {
  .ReactModal__Content {
    top: 15px;
    transform: translate(-50%, 0);
  }
  .ReactModal__Content .modal-body {
    max-height: calc(100vh - 180px);
  }
}
*/
