// Here you can add other styles
body {
  background-color: rgb(240, 243, 245);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.header-fixed .app-header,
.sidebar-fixed .sidebar {
  z-index: 100;
}

.subtle {
  color: #666;
}

a.subtle:hover {
  color: #333;
  text-decoration: none;
}

// Fixed center column
.col-fixed {
  margin: 0 auto;
}

@media (min-width: 1258px) {
  .col-fixed {
    width: 998px;
  }
}

.editor-header-left {
  position: fixed;
  width: 200px;
}

.editor-header-right {
  width: 100%;
  padding: 0 30px;
  margin-left: 200px;
}

@media (max-width: 991.98px) {
  .app-header {
    text-align: left;
  }
  .editor-header-right {
    margin-left: 0;
  }
  .container-fluid {
    padding: 0 10px !important;
  }
}

.table th {
  border-top: 0;
}
.table thead th {
  border-bottom: 0;
}

.table td {
  border-top: 1px solid #ebeef0;
}

table.clickable tbody tr {
  cursor: pointer;
}
table.clickable tbody tr:hover {
  background-color: #f7fafc;
}

.header-navigation {
  margin-bottom: 15px;
}
.header-navigation i {
  margin-right: 5px;
}
.header-navigation a {
  text-decoration: none;
  color: #596670;
}
.header-navigation a:hover {
  color: #23282c;
}

.card-header {
  background: none;
  border-bottom: 0;
  padding-top: 1.25rem;
  padding-bottom: 0;
}
.card-header span {
  font-weight: 500;
  font-size: 16px;
}
.card-header-actions {
  margin-right: 0;
}
.card-body p {
  margin: 0;
  padding: 0;
}

.btn.btn-xsmall {
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}

a.simple {
  text-decoration: none;
}
a.tableclickable {
  margin: -0.75rem;
  padding: 0.75rem;
  display: block;
}

.dropdown-item {
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}

.nav-tabs .nav-link.active {
  background: #f7f7f7;
  border-bottom-color: #f7f7f7;
}

.tab-content {
  background: #f7f7f7;
}
