.popover {
  max-width: 400px;
}
.popover-header {
  font-size: 18px;
}
.popover-body {
  overflow: auto;
  max-height: 400px;
  font-size: 14px;
}
